
import 'normalize.css/normalize.css'
import './styles/index.scss'

document.addEventListener("DOMContentLoaded", () => {

    let urlParams = new URLSearchParams(window.location.search)
    let nameParam = urlParams.get('name')
    let timeParam = urlParams.get('time')

    console.log(nameParam, timeParam)

    let currentTab
    if (nameParam && timeParam) currentTab = 2 // ha completato l'esame
    else currentTab = 0 // deve iniziare l'esame
    
    showTab(currentTab) // Display the current tab

    // DOM elements
    const nextButton = document.getElementById('nextBtn')
    
    // Event listeners
    nextButton.addEventListener('click', function() {
        // This function will figure out which tab to display
        const x = document.getElementsByClassName("tab")
        // Exit the function if any field in the current tab is invalid:
        if (!validateForm()) return false
        // Hide the current tab:
        x[currentTab].style.display = "none"
        // Go to next tab
        currentTab = currentTab + 1
        // Display the current tab
        showTab(currentTab)
        startTimer();
    })

    function showTab(n) {
        // This function will display the specified tab of the form ...
        const x = document.getElementsByClassName("tab")
        x[n].style.display = "block"
    }

    function validateForm() {
        // This function deals with validation of the form fields
        const x = document.getElementsByClassName("tab")
        const y = x[currentTab].getElementsByTagName("input")
        let valid = true;
        // A loop that checks every input field in the current tab:
        for (let i = 0; i < y.length; i++) {
            // If a field is empty...
            if (y[i].value == "") {
                // add an "invalid" class to the field:
                y[i].className += " invalid"
                // and set the current valid status to false:
                valid = false
            }
        }
        return valid // return the valid status
    }

    function startTimer() {
        const timer = document.getElementById('timer')
        let rawValue = timer.value
        let m, s = 0
        setInterval(function() {
            rawValue = timer.value
            m = Number(rawValue.split(':')[0])
            s = Number(rawValue.split(':')[1])
            
            // calculate new timer
            if (s < 60) { s++ } 
            else { s = 0; m++; }

            // Print new timer
            if (s < 10) s = "0" + s
            if (m < 10) m = "0" + m
            timer.value = m + ":" + s
        }, 1000)
    }

});
